.menu.open > path:nth-child(1) {
  transform-origin: center center;
  animation: to-cross-first 300ms ease-in-out forwards;
}

.menu.open > path:nth-child(2) {
  transform-origin: center center;
  animation: to-cross-second 300ms ease-in-out forwards;
}


.menu.close> path:nth-child(1) {
  transform-origin: center center;
  animation: to-cross-first-reverse 300ms ease-in-out forwards;
}

.menu.close > path:nth-child(2) {
  transform-origin: center center;
  animation: to-cross-second-reverse 300ms ease-in-out forwards;
}

@keyframes to-cross-first{
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(4px);
  }
  100% {
    transform: rotate(-45deg) translateY(4px);
  }
}

@keyframes to-cross-second{
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-4px);
  }
  100% {
    transform: rotate(45deg) translateY(-4px);
  }
}


@keyframes to-cross-first-reverse{
  0% {
    transform: rotate(-45deg) translateY(4px);
  }
  50% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes to-cross-second-reverse{
  0% {
    transform: rotate(45deg) translateY(-4px);
  }
  50% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(0px);
  }
}


.fade-out {
  animation: 1s fadeOut;
  animation-fill-mode: forwards;
}


@keyframes fadeOut {
  99% {
    visibility: visible;
  }
  100% {
    visibility: hidden
  }
}